/*
 * Fade In Animation
 */
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/*
 * Relative top to base Animation
 */
@keyframes relative-top-to-base-fade {
  from {
    opacity: 0;
    top: -15px;
  }
  to   {
    opacity: 1;
    top: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes relative-top-to-base-fade {
  from {
    opacity: 0;
    top: -15px;
  }
  to   {
    opacity: 1;
    top: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes relative-top-to-base-fade {
  from {
    opacity: 0;
    top: -15px;
  }
  to   {
    opacity: 1;
    top: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes relative-top-to-base-fade {
  from {
    opacity: 0;
    top: -15px;
  }
  to   {
    opacity: 1;
    top: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes relative-top-to-base-fade {
  from {
    opacity: 0;
    top: -15px;
  }
  to   {
    opacity: 1;
    top: 0;
  }
}