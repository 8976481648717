
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600');

// Variables
@import "variables";

// Animations
@import "animations.scss";

// Font-Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Social Bootstrap
@import "bootstrap-social";

body {
  padding-top: 96px;
}

@media (max-width: 767px) {
  body {
    padding-top: 56px;
  }
}

/*
 * Default Navbar
 */
.navbar-default {
  min-height: 50px;
  border-bottom: $brand-color 2px solid;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  // Overriding the collapsed border top color
  .navbar-collapse,
  .navbar-form {
    border-color: $brand-color;
  }

  .navbar-header {
    width: 100%;
  }

  // Styling the collapse button for smaller devices
  .offcanvas-toggle {
    position: relative;
    float: left !important;

    margin-top: 31px;
    margin-bottom: 31px;
    margin-right: 15px;
  }

  // Styling the brand
  .navbar-brand {
    padding: 11px 12px;
    height: auto !important;
    outline-color: $brand-color;

    img {
      height: 74px;
    }
  }

  @media (max-width: 767px) {
    .offcanvas-toggle {
      margin-top: 11px;
      margin-bottom: 11px;
    }

    .navbar-brand {
      padding: 6px 12px;
      height: auto !important;

      img {
        height: 44px;
      }
    }
  }

  .search-bar {
    display: inline-block;
    border: none;
    margin: 28px 0 28px 12px;
    border-bottom: 1px solid #dedede;
    padding: 0;
    width: 240px;
    transition: all ease-in 0.2s;
    position: relative;

    &.focus {
      width: 580px;
      border-color: darken(#dedede, 25%);

      .btn {
        opacity: .40;
      }
      input[type='text'] {
        opacity: 1 !important;
      }
    }

    .btn {
      position: absolute;
      top: 5px;
      z-index: 10;
      color: #555555;
      opacity: .27;
      background-color: transparent;
      border-radius: 50%;
      transition: background-color ease-in 0.2s, color ease-in 0.2s, opacity ease-in 0.2s;
      padding: 2px 8px;

      &.expand-bar {
        display: none;
        top: 5px;
      }

      &:hover, &:focus, &.focus {
        outline: none;
        opacity: .40;
      }

      &:active, &.active {
        outline: none;
        background-image: none;
        box-shadow: none;
        opacity: .87;
        background-color: #dedede;
        color: #fff;
      }
    }

    .form-group {
      display: inline-block;
      width: 100%;

      input[type='text'] {
        background-color: transparent;
        border: none;
        box-shadow: none;
        opacity: .87;
        padding: 10px 12px 10px 42px !important;
        width: 100%;
        transition: opacity ease-in 0.2s;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .results {
      /* Styling search results | hidden for the moment. */
      display: none;
      position: absolute;
      left: 0;
      background-color: #fff;
      width: 100%;
      height: 430px;

      border-bottom: 1px solid #f0f0f0;
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;

      -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);

      .result-list {
        list-style: none;
        padding: 15px 0;

        .result-item {
          padding: 8px 20px;

          &:hover {
            background-color: #f6f6f6;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .search-bar {
      float: right !important;
      margin: 8px 0;
      width: 36px;
      max-width: 36px;
      overflow: hidden;
      padding-left: 36px;
      border-color: transparent;

      &.showing {
        position: absolute;
        left: 15px;
        right: 15px;
        background-color: #fff;
        margin: 0;
        padding: 8px 0;
        width: 100%;
        max-width: none;
        overflow: hidden;

        border-bottom: none;

        .btn {
          &.expand-bar {
            display: none;
          }
        }
      }

      &.focus {
        border-color: transparent;
      }

      .btn {
        display: none;

        &.expand-bar {
          display: block;
          left: 0;
        }
      }
    }
  }

  // Horizontal center for menu links
  .navbar-nav > li > a {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

/*
 * Profile picture!
 */
.profile-pic {
  float: right;
  padding: 11px 11px;
  height: 96px; /* 74px after paddings */
  width: 96px;

  .clip {
    display: block;
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid transparent;
    overflow: hidden;
    transition: border-color ease-in 0.2s;

    &:hover {
      border-color: $brand-color;
    }

    img {
      height: 100%;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .profile-pic {
    float: none;
    margin: 0 auto;
  }
}

.popover {
  text-align: center;

  hr {
    margin: 12px 0;
  }
}

/*
 * Sidebar Offcanvas
 */
.container-fluid {
  &.in {
    .sidebar-offcanvas {
      transform: translateX(0);
    }
  }

  .sidebar-offcanvas {
    position: fixed;
    width: 260px;
    top: 98px;
    left: 0;
    bottom: 0;
    z-index: 1039;
    display: block;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: $body-bg;

    transition: all 300ms cubic-bezier(.67,-0.38,.43,1.53);
    transform: translateX(-100%);

    .offcanvas-toggle {
      position: absolute;
      top: 12px;
      left: 18px;
    }

    hr {
      border: 0;
      border-bottom: 1px solid rgba(0,0,0,0.12);
      -webkit-margin-before: 0.5em;
      -webkit-margin-after: 0.5em;
    }

    /* Sidebar navigation */
    .nav-sidebar {
      font-size: 1.2rem;
    }
    .nav-sidebar > li > a {
      padding: 4px 20px;
      color: rgba(0,0,0,0.7);
      font-size: 1.8rem;
      transition: background-color ease-in 0.2s, color ease-in 0.2s;
    }
    .nav-sidebar > li > a:hover,
    .nav-sidebar > li > a:focus {
      color: $brand-color;
      background-color: #f5f5f5;
    }
    .nav-sidebar > .active > a,
    .nav-sidebar > .active > a:hover,
    .nav-sidebar > .active > a:focus {
      color: #f5f5f5;
      background-color: $brand-color;
    }
    & section {
      $duration: 500ms;
      position: relative;
      animation: relative-top-to-base-fade $duration;
      -moz-animation: relative-top-to-base-fade $duration; /* Firefox */
      -webkit-animation: relative-top-to-base-fade $duration; /* Safari and Chrome */
      -o-animation: relative-top-to-base-fade $duration; /* Opera */
    }
  }

  @media (max-width: 767px) {
    .sidebar-offcanvas {
      top: 0;
      z-index: 1050;
    }
  }
}

/*
 * Offcanvas menu toggle
 */
.offcanvas-toggle {
  padding: 9px 10px;

  background-color: transparent;
  background-image: none;

  outline-color: $brand-color;

  border: 1px solid transparent;
  border-radius: 1px;
  border-color: transparent;

  transition: border-color ease-in 0.2s;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $brand-color;
  }

  .icon-bar {
    display: block;

    background-color: $brand-color;
    transition: all ease-in 0.2s;
    opacity: 0;

    width: 22px;
    height: 2px;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .icon-bar.cross {
    position: absolute;
    top: 16px;
    opacity: 1;
    margin: 0;

    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center;
  }

  .icon-bar.cross + .icon-bar.cross {
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.collapsed {
    .icon-bar {
      opacity: 1;
    }

    .icon-bar.cross {
      opacity: 0;
    }
  }
}

/*
 * Main content
 */
.container-fluid {

  &.in {
    .main {
      padding-left: 280px;
    }
  }

  .main {
    padding: 0;
    transition: padding-left ease-in-out 0.2s;

    .view-loader {
      position: fixed;
      top: 100px;
      bottom: 0;
      left: 16.6666666667%; // To not overlap with the sidebar
      right: 0;
      z-index: 1050;
      overflow: hidden;
      background-color: $body-bg;

      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -webkit-flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      object {
        width: 96px;
        height: 96px;
      }
    }
  }

  @media (max-width: 767px) {
    &.in {
      .main {
        padding-left: 0;
      }
    }
  }
}



/*
 * Horizontal separator
 */
.horizontal-separator {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 12px;
}
.horizontal-separator > .msg {
  position: relative;
  display: inline-block;
}
.horizontal-separator > .msg:before,
.horizontal-separator > .msg:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #000;
}
.horizontal-separator.secondary > .msg:before,
.horizontal-separator.secondary > .msg:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: $laravel-border-color;
}
.horizontal-separator > .msg:before {
  right: 100%;
  margin-right: 15px;
}
.horizontal-separator > .msg:after {
  left: 100%;
  margin-left: 15px;
}

/*
 * White background and padding for containers
 */
.white-panel {
  background-color: #fff;
  margin: 30px;
  padding: 30px;

  h2 {
    margin-bottom: 34px;
  }
}

@media (min-width: 971px) {
  .white-panel {
    h2 {
      margin-top: 5vw;
    }
  }
}

/*
 * Form fixes
 */
.row.padding {
  div {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.control-label {
  font-size: 16px;
}

label {

}

/*
 * Buttons
 */
.btn {
  transition: background-color ease-in 0.2s, border-color ease-in 0.2s, all ease-in 0.2s;
}
.btn-col {
  a {
    margin-left: 8px;
  }
}

/*
 * Buttons
 */
.alerts {
  transition: all ease-in 0.2s;
}

.alert-info {
  a {
    color: darken($alert-info-bg, 20%);
  }
}

/*
 * Fixing anchor cursor with no link
 */
a {
  cursor: pointer;
}

/*
 * Adding tag style
 */
div.tag {
  display: inline-block;
  background-color: $brand-color;
  padding: 4px 8px;
  border-radius: $border-radius-base;
  margin-right: 4px;
  margin-bottom: 6px;

  .name {
    color: #fff;
    cursor: default;
  }

  .close {
    color: #fff;
    padding-left: 8px;
    opacity: 0.8;
    filter: alpha(opacity=80);
    transition: opacity ease-in 0.2s;
  }
}

div.add-tag {
  display: inline-block;
  background-color: $brand-color;
  padding: 4px 8px;
  border-radius: $border-radius-base;
  margin-left: 4px;
  margin-bottom: 6px;
  transition: width ease-in 0.2s;

  .btn {
    padding: 0 12px;
    border: none;
    vertical-align: baseline;
    background: transparent;
    color: #fff;
  }

  form {
    display: inline-block;
  }

  .tag-input {
    line-height: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    color: #fff;
    padding-left: 4px;
    padding-right: 4px;

    &::-webkit-input-placeholder {
      color: #fefefe;
    }
    &:-moz-placeholder {
      color: #fefefe;
    }
    &::-moz-placeholder {
      color: #fefefe;
    }
    &:-ms-input-placeholder {
      color: #fefefe;
    }
  }
}

/*
 * Adding note style
 */
div.note {
  background-color: #fefefe;
  border-radius: $border-radius-base;
  border: 1px solid darken(#fefefe, 10%);
  margin-left: 20px;
  padding: 6px 20px 20px 20px;
  margin-bottom: 12px;

  span.timestamp {
    font-size: 11px;
    font-style: italic;
  }

  span.timestamp:before {
    content: "—";
    margin-right: 6px;
  }
}

/*
 * Adding user row style
 */
div.user-row {
  transition: all .2s ease-in-out;
  &.selected {
    background-color: #f8f8f8;
    transform: scale(1.03);
    padding-bottom: 20px;
    -webkit-box-shadow: 2px 2px 6px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 6px -2px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 6px -2px rgba(0,0,0,0.75);
  }
  .details {
    text-align: center;
    .profile-picture {
      margin: 0 auto;
      height: 96px;
      border-radius: 50%;
    }
    .name {
      font-size: 34px;
      line-height: 1.1;
      font-weight: bold;
    }
    .email {
      font-weight: bold;
    }
    .position {
      font-style: italic;
    }
    .separator {
      span {
        display: block;
        width: 40%;
        height: 1px;
        background-color: $laravel-border-color;
        margin: 6px auto;
      }
    }
  }
}

/*
 * Adding table div support
 */
div.row.bt-table {
  padding: 8px;
  line-height: 1.6;
  vertical-align: top;
  border-top: 1px solid #ddd;

  .thead {
    .th {
      vertical-align: bottom;
      padding: 8px;
      line-height: 1.6;
      font-weight: bold;
    }
  }
}
div.tbl {
  display: table;
  div.t-row {
    display: table-row;
    div.t-column {
      display: table-cell;
    }
  }
}

/*
 * Default sizes for loading ring svg
 */
object.ring-small {
  width: 24px;
  height: 24px;
}

object.rolling-small {
  width: 16px;
  height: 16px;
}

.main .navbar-toggle {
  background-color: #f8f8f8;
  border: 1px solid #dedede;

  .icon-bar {
    background-color: #dedede;
  }
}